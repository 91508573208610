@import url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap');

html {
    height: 100%;
}
html > *{
    font-family: 'Ubuntu', sans-serif !important;
}
body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.show {
    padding-bottom: 0.5em;
}

.nav-item > .active {
    border-color: #00325273 #00325273 #fff !important;
}

#root{
    height: 100%;
}